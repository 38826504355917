import React, {useState, useEffect} from "react";
import {
    AmplifyAuthContainer,
    AmplifyAuthenticator,
    AmplifyForgotPassword,
    AmplifySignIn
} from "@aws-amplify/ui-react";
import Application from "./Application";
import {AuthState, onAuthUIStateChange, UsernameAlias} from '@aws-amplify/ui-components';
import {Hub, API, I18n} from 'aws-amplify';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { environment } from "./environment";

const AuthApplication = () => {
    const [authState, setAuthState] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    return authState === AuthState.SignedIn && user ? (
        <Application />
    ) : (
        <AmplifyAuthContainer>
            <AmplifyAuthenticator hideToast={false}>
                <AmplifySignIn
                    usernameAlias={UsernameAlias.email}
                    slot="sign-in"
                    hideSignUp={true} />
                <AmplifyForgotPassword
                    slot="forgot-password"
                    usernameAlias={UsernameAlias.email}
                />
            </AmplifyAuthenticator>
        </AmplifyAuthContainer>
    );
};

I18n.setLanguage('es');

const dict = {
    'es': {
        'Sign in to your account': 'Inicia sesión en tu cuenta',
        'Back to Sign In': 'Regresar a inicio de sesión',
        'Send Code': 'Enviar código',
        'Username cannot be empty': 'El correo electrónico es requiredo para enviar el código',
        'Sign In': 'Iniciar sesión',
        'Email Address': 'Correo electrónico',
        'Email Address *': 'Correo electrónico',
        'Enter your email address': 'Ingresa tu correo electrónico',
        'Password *': 'Contraseña',
        'Password cannot be empty': 'La contraseña es requerida',
        'Incorrect username or password.': 'Datos incorrectos',
        'Forgot your password?': '¿Olvidaste tu contraseña?',
        'Reset password': 'Recuperar contraseña',
        'Reset your password': 'Recupera tu contraseña'
    }
};

I18n.putVocabularies(dict);

Hub.listen('auth', (data) => {
    switch (data.payload.event) {
        case 'signIn':
            const fingerprint = Base64.stringify(sha256(
                navigator.buildID +
                navigator.productSub +
                navigator.userAgent +
                navigator.oscpu));

            API.post(environment, "/api/v1/security/user-events", {
                body: {
                    event: 'sign-in',
                    fingerprint: fingerprint,
                    appCodeName: navigator.appCodeName,
                    appName: navigator.appName,
                    appVersion: navigator.appVersion,
                    buildID: navigator.buildID,
                    hardwareConcurrency: navigator.hardwareConcurrency,
                    oscpu: navigator.oscpu,
                    platform: navigator.platform,
                    product: navigator.product,
                    productSub: navigator.productSub,
                    userAgent: navigator.userAgent,
                }
            });
            break;
        default:
            break;
    }
});

export default AuthApplication;
