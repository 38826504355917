
import { Auth } from 'aws-amplify';

export const environment = "production";

export const awsapi = {
    endpoints: [
        {
            name: "production",
            endpoint: "https://2ffnfyeovi.execute-api.us-west-2.amazonaws.com/production",
            custom_header: async () => {
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
        }
    ]
};

