import React, {Suspense, useState} from "react";
import './Dashboard.css';
import TopMenu from '../tools/TopMenu';
import RightMenu from '../tools/RightMenu';
import {Container, Loader} from "semantic-ui-react";


const Dashboard = props => {

    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <div className="dashboard">
            <div className="menu">
                <TopMenu username={props.username} setToggleMenu={setToggleMenu} toggleMenu={toggleMenu}  />
            </div>
            <div className="app-content">
                <RightMenu admin={props.admin} toggleMenu={toggleMenu} />
                <Container fluid>
                    <Suspense fallback={<Loader active inline='centered' />}>
                        {props.children}
                    </Suspense>
                </Container>
            </div>
        </div>
    );
}

export default Dashboard;
