import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Provider } from 'react-redux';
import store from './redux/store';
import AuthApplication from "./AuthApplication";
import { awsapi } from './environment';

Amplify.configure({
  Auth: {
      identityPoolId: awsconfig.aws_cognito_identity_pool_id,
      region: awsconfig.aws_cognito_region,
      userPoolId: awsconfig.aws_user_pools_id,
      userPoolWebClientId: awsconfig.aws_user_pools_web_client_id
  },
  API: awsapi
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <AuthApplication />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
