import React, {lazy, useEffect, useState} from "react";
import './Application.css';
import 'semantic-ui-css/semantic.min.css';
import { Switch} from "react-router";
import { BrowserRouter, Route } from 'react-router-dom';

import Dashboard from './components/layout/Dashboard';
import {Auth} from "aws-amplify";
import {Dimmer, Loader} from "semantic-ui-react";

const LazyHome = lazy(() => import('./components/pages/home/Home'));
const LazyProjects = lazy(() => import('./components/pages/projects/Projects'));
const LazyUsers = lazy(() => import('./components/pages/users/Users'));
const LazySettings = lazy(() => import('./components/pages/settings/Settings'));
const LazyLogout = lazy(() => import('./components/pages/common/Logout'));
const LazySat = lazy(() => import('./components/pages/sat/Sat'));

const Application = () => {

    const [connection] = useState(true);
    const [username, setUsername] = useState();
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            setUsername(user.attributes.email);
            setAdmin(user.attributes['custom:Administrator'] === 'true');
        }).catch(err => console.log(err));
    }, []);

    if (!connection) {
        return (
            <Dimmer active>
                <Loader content='Conectando a la base de datos' />
            </Dimmer>
        );
    }

    return (
        <BrowserRouter>
            <Dashboard admin={admin} username={username}>
                <Switch>
                    <Route exact path="/" component={LazyHome} />
                    <Route path="/projects" component={LazyProjects} />
                    <Route path="/settings" component={LazySettings} />
                    <Route path="/logout" component={LazyLogout} />
                    {admin && [
                    <Route path="/users" component={LazyUsers} />,
                    <Route path="/sat" component={LazySat} />
                    ]}
                </Switch>
            </Dashboard>
        </BrowserRouter>
    );
}

export default Application;
