import React, {useEffect, useState} from "react";
import {Icon, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';

const RightMenu = props => {

    const [page, setPage] = useState('/');
    const { toggleMenu } = props;
    const location = useLocation();

    useEffect(() => {
        setPage(location.pathname)
    }, [location.pathname]);

    const onClickMenuItem = (page) => {
        setPage(page)
    }

    if (toggleMenu) {
        return (
            <Menu icon inverted vertical>
                <Menu.Item
                    as={Link}
                    to='/'
                    active={page === '/'}
                    onClick={() => {onClickMenuItem('/')}}>
                    <Icon name='home' size='large' />
                </Menu.Item>

                <Menu.Item
                    as={Link}
                    to='/projects'
                    active={page.startsWith('/projects')}
                    onClick={() => {onClickMenuItem('/projects')}}>
                    <Icon name='book' size='large' />
                </Menu.Item>

                {props.admin &&
                <Menu.Item
                    as={Link}
                    to='/users'
                    active={page.startsWith('/users')}
                    onClick={() => {onClickMenuItem('/users')}}>
                    <Icon name='users' size='large' />
                </Menu.Item>}

                {props.admin &&
                <Menu.Item
                    as={Link}
                    to='/sat'
                    active={page.startsWith('/sat')}
                    onClick={() => {onClickMenuItem('/sat')}}>
                    <Icon name='folder' size='large' />
                </Menu.Item>}

                <Menu.Item
                    as={Link}
                    to='/settings'
                    active={page.startsWith('/settings')}
                    onClick={() => {onClickMenuItem('/settings')}}>
                    <Icon name='settings' size='large' />
                </Menu.Item>

            </Menu>
        )
    } else {
        return (
            <Menu inverted vertical size="large">
                <Menu.Item
                    icon='home'
                    name='Inicio'
                    as={Link}
                    to='/'
                    active={page === '/'}
                    onClick={() => {onClickMenuItem('/')}}
                />

                <Menu.Item
                    icon='book'
                    name='Proyectos'
                    as={Link}
                    to='/projects'
                    active={page.startsWith('/projects')}
                    onClick={() => {onClickMenuItem('/projects')}}
                />

                {props.admin &&
                <Menu.Item
                    icon='users'
                    name='Usuarios'
                    as={Link}
                    to='/users'
                    active={page.startsWith('/users')}
                    onClick={() => {onClickMenuItem('/users')}}
                />}

                {props.admin &&
                <Menu.Item
                    icon='folder'
                    name='SAT'
                    as={Link}
                    to='/sat'
                    active={page.startsWith('/sat')}
                    onClick={() => {onClickMenuItem('/sat')}}
                />}

                <Menu.Item
                    icon='settings'
                    name='Configuraciones'
                    as={Link}
                    to='/settings'
                    active={page.startsWith('/settings')}
                    onClick={() => {onClickMenuItem('/settings')}}
                />

            </Menu>
        );
    }
}

export default RightMenu;
