import React from "react";
import {Icon, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";

const TopMenu = props => {

    const { setToggleMenu, toggleMenu } = props;

    const onToggleMenuClick = () => {
        setToggleMenu(!toggleMenu)
    }

    return (
        <Menu inverted size="large">
            <Menu.Item header>Clypeus</Menu.Item>
            <Menu.Item onClick={onToggleMenuClick}>
                <Icon name='bars' />
            </Menu.Item>

            <Menu.Item
                position="right">
                {props.username}
            </Menu.Item>

            <Menu.Item
                as={Link}
                to='/logout'>
                Cerrar sesión
            </Menu.Item>
        </Menu>
    );
}

export default TopMenu;